import React, { useState, useEffect } from 'react';
import './footer.css';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWeb3React } from "@web3-react/core";
import { useAccountStats } from '../hooks/useStats';
import { Link } from 'react-router-dom';
import imgBlack from '../images/logo-white.png';

export default function Footer() {
    const context = useWeb3React();
    const { account } = context;
    const [referrallink, setReferrallink] = useState(
        "Please connect your wallet"
    );
    const [refcopy, setRefcopy] = useState(false);
    let base_url = `${window.location.origin}/stake?ref=`;
    const [updater] = useState(1);
    const stats = useAccountStats(updater)

    useEffect(() => {
        if (account) {
            setReferrallink(`${base_url}${account}`);
        } else {
            setReferrallink("Please connect your wallet");
        }
    }, [account, base_url]);

    return (
        <React.Fragment>
            <div className="staking-overflow mb-5">
                <div className="container tab-content2 rounded-8 mt-4 mt-sm-5">
                    <section className='ref-section'>
                        <article className="presentation">
                            <h1 className="page-title text-dark">Refer Friends. Earn Crypto Together.</h1>
                            <p className="description">Earn up to 2.5% commission on every stake.</p>
                        </article> 


                        <div className="input-group mx-3 my-3">
                            <input type="text" className="form-control" value={referrallink} />
                            <span className="input-group-text" id="basic-addon2">
                                <CopyToClipboard text={`${base_url}${account}`} onCopy={() => {
                                    setRefcopy(true);
                                    setTimeout(() => {
                                        setRefcopy(false);
                                    }, 2000)
                                }}>
                                    <button className="button" type="button">
                                        <img src="https://s2.svgbox.net/octicons.svg?ic=copy" alt="Icon" />
                                    </button>
                                </CopyToClipboard>
                                <span>{refcopy && 'copied'}</span>
                            </span>
                        </div>
                        {/* <input type="text" value={referrallink} className="mx-3 my-3" /> */}
                        {/* <div className="text">{referrallink}</div> */}
                        {/* <div> */}
                        {/* <CopyToClipboard text={`${base_url}${account}`} onCopy={() => {
                                    setRefcopy(true);
                                    setTimeout(() => {
                                        setRefcopy(false);
                                    }, 2000)
                                }}>
                                    <button className="button" type="button">
                                        <img src="https://s2.svgbox.net/octicons.svg?ic=copy" alt="Icon" />
                                    </button>
                                </CopyToClipboard>

                                <span>{refcopy && 'copied'}</span> */}
                        {/* </div> */}

                        <div className="d-flex justify-content-center">
                            <div className="p-2 bd-highlight level-span">Level 1 &nbsp; :&nbsp;  0.5%</div>
                            <div className="p-2 bd-highlight level-span">Level 2 &nbsp; :&nbsp;  0.5%</div>
                            <div className="p-2 bd-highlight level-span">Level 3 &nbsp; :&nbsp;  0.5%</div>
                            <div className="p-2 bd-highlight level-span">Level 4 &nbsp;:&nbsp;  0.5%</div>
                            <div className="p-2 bd-highlight level-span">Level 5 &nbsp;:&nbsp;  0.5%</div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="p-2 bd-highlight level-span mt-3">Your Referral Earning : {stats.rewardRef} </div>
                            <div className="p-2 bd-highlight level-span mt-3">Your Referral Count : {stats.countRef} </div>
                        </div>

                    </section>

                </div>
            </div>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <a href="#sec" className="logo d-block">
                                        <img src={imgBlack} className="w-100" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-12 mt-5 mt-md-0 d-flex justify-content-end">
                            <div className="social-icon d-flex gap-3">
                                <a target="_blank" rel="noreferrer" href="#sec" className="caption-color">
                                    <i className="fa-brands fa-facebook-square"></i>
                                </a>
                                <a target="_blank" rel="noreferrer" href="#sec" className="caption-color">
                                    <i className="fa-brands fa-twitter"></i>
                                </a>
                                <a target="_blank" rel="noreferrer" href="#sec" className="caption-color">
                                    <i className="fa-brands fa-youtube"></i>
                                </a>
                                <a target="_blank" rel="noreferrer" href="#sec" className="caption-color">
                                    <i className="fa-brands fa-telegram"></i>
                                </a>
                                <a target="_blank" rel="noreferrer" href="#sec" className="caption-color">
                                    <i className="fa-brands fa-reddit"></i>
                                </a>
                                <a target="_blank" rel="noreferrer" href="#sec" className="caption-color">
                                    <i className="fab fa-connectdevelop"></i>
                                </a>
                                <a target="_blank" rel="noreferrer" href="#sec" className="caption-color">
                                    <i className="fa-brands fa-github"></i>
                                </a>
                                <a target="_blank" rel="noreferrer" href="#sec" className="caption-color">
                                    <i className="fa-brands fa-line"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-12 mt-3  d-flex justify-content-between text-white">
                            <div>Copyright © 2023 MacaronStake.io. All Right Reserve.</div>
                            <div><Link to="/privacy-policy" className='text-white'>Privacy Policy</Link></div>

                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}
