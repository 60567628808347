import React , {useState} from 'react';
import { supportNetwork } from '../helper/network';
import { useWeb3React } from "@web3-react/core";
import Modal from 'react-bootstrap/Modal';

export default function NetworkMenu() {
    const { chainId } = useWeb3React();
    const [networkshow, setNetworkshow] = useState(false);

    const switchNetwork = (networkid) => {
        try {
            // @ts-ignore
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${networkid.toString(16)}` }]
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <div className="network on-mobile mr-3" style={{ "pointerEvents": "auto" }} onClick={() => {
                setNetworkshow(!networkshow);
            }}>

                <img width={18} src={`${(chainId && supportNetwork[chainId]) ? supportNetwork[chainId].image : supportNetwork['default'].image}`} alt="Switch Network" className="mx-2" />
                <span className="ml-2 hide-on-mobile">BSC MAINNET</span>

            </div>


            <Modal
                show={networkshow}
                onHide={() => setNetworkshow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Network</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            {Object.keys(supportNetwork).map(function (key) {
                                if (key === 'default') return (<React.Fragment key={key}></React.Fragment>);
                                return (
                                    <div className="col-12" key={key}>
                                        <button className="btn btn-dark modal-btn-connect" onClick={() => {
                                            switchNetwork(supportNetwork[key].chainId);
                                            setNetworkshow(false);
                                        }}>
                                            <div className="div-modal-btn">
                                                <img src={supportNetwork[key].image} alt="Meta-mask-Im" className="modal-img" />
                                                <div className="text-modal-line">{supportNetwork[key].name}</div>
                                            </div>
                                        </button>
                                    </div>
                                )
                            })
                            }

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
