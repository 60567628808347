export const contract = {
    56: {
        stakingAddress: "0xf39ad3fe700de7ff341c0f28547e63ac3ec5f188",
        multicallAddress: "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116"
    },
    // 97 : {
    //     stakingAddress : "0x3718806008d20c71ac5a38c8ec551633aa948cff",
    //     multicallAddress : "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18"
    // },
    'default': {
        stakingAddress: "0xf39ad3fe700de7ff341c0f28547e63ac3ec5f188",
        multicallAddress: "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116"
    }

}

