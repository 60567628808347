import { useEffect, useState } from "react"
import { getMultiCall, getStakingContract } from "../helper/useContracts";
import { useWeb3React } from "@web3-react/core";
import { supportNetwork } from "../helper/network";
import tokenAbi from '../json/token.json';
import { getWeb3 } from "../helper/connectors";



export const useCommonStats = (update) => {
  const context = useWeb3React();
  const { chainId } = context;
  let web3 = getWeb3(chainId);

  const [stats, setStats] = useState({
    totalRewardsDistribution: 0,
    totalStake: 0,
    totalWithdrawal: 0,
    tokenAddress: '',
    tokenDeciamls: 0,
    userCount: 0


  });

  const sc = getStakingContract(chainId)

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getMultiCall([
          sc.methods.totalRewardsDistribution(),
          sc.methods.totalStake(),
          sc.methods.totalWithdrawal(),
          sc.methods.token(),
          sc.methods.getTotalNumberOfUser()
        ], supportNetwork[chainId ? chainId : 'default'].chainId);

        let tc = new web3.eth.Contract(tokenAbi, data[3]);
        let decimals = await tc.methods.decimals().call();

        setStats({
          totalRewardsDistribution: data[0] / Math.pow(10, decimals),
          totalStake: data[1] / Math.pow(10, decimals),
          totalWithdrawal: data[2] / Math.pow(10, decimals),
          tokenAddress: data[3],
          tokenDeciamls: decimals,
          userCount: data[4]
        });
      }
      catch (err) {
        console.log(err.message);
      }
    }


    fetch();


    // eslint-disable-next-line
  }, [update, chainId]);

  return stats;
}

export const useAccountStats = (updater) => {
  const context = useWeb3React();
  const { chainId, account } = context;
  let web3 = getWeb3(chainId);
  const [stats, setStats] = useState({
    balance: 0,
    isApprove: false,
    totalStake: 0,
    orderIds: '',
    totalRewardEarn: 0,
    tokenAddress: '',
    tokenDeciamls: 0,
    rewardRef: 0,
    countRef: 0,
    referrer: 0
  });

  const sc = getStakingContract(chainId);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getMultiCall([
          sc.methods.balanceOf(account),
          sc.methods.investorOrderIds(account),
          sc.methods.totalRewardEarn(account),
          sc.methods.token(),
          sc.methods.accounts(account)
        ], supportNetwork[chainId ? chainId : 'default'].chainId);


        let tc = new web3.eth.Contract(tokenAbi, data[3]);
        let tokenData = await getMultiCall([
          tc.methods.balanceOf(account),
          tc.methods.allowance(account, sc._address),
          tc.methods.decimals()
        ], supportNetwork[chainId ? chainId : 'default'].chainId)



        setStats({
          balance: tokenData[0] / Math.pow(10, tokenData[2]),
          isApprove: tokenData[1] / Math.pow(10, tokenData[2]),
          totalStake: data[0] / Math.pow(10, tokenData[2]),
          orderIds: data[1],
          totalRewardEarn: data[2] / Math.pow(10, tokenData[2]),
          tokenAddress: data[3],
          tokenDeciamls: tokenData[2],
          rewardRef: data[4][1] / Math.pow(10, tokenData[2]),
          countRef: data[4][2],
          referrer: data[4][0]
        })
      }
      catch (err) {
        console.log(err.message);
      }
    }

    if (account) {
      fetch();
    }
    else {
      setStats({
        balance: 0,
        isApprove: false,
        totalStake: 0,
        orderIds: '',
        totalRewardEarn: 0
      })
    }
    // eslint-disable-next-line
  }, [account, updater, chainId]);

  return stats;
}
