import React, { useState } from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard";
// import bannerImg from '../images/banner.png';
// import sideImg from '../images/side-img.png';
import work from '../images/work.jpg';
import copyImg from '../images/copy.png';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';

export default function Home() {
    const [addrcopy, setAddrcopy] = useState(false);

    return (
        <>
            <div id="hero-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-4">
                                <h2 className='text-dark'>$FM SAFER CRYPTO INVESTMENT ECOSYSTEM STAKING</h2>
                            </div>
                            <p className='text-dark'>
                                Join Macaron Finance Stake Refer L1-L5 Program With Smart Contract Now Monetize Your Traffic And Earn Crypto $FM 2.5% Commission.
                            </p>
                            <div className="d-flex gap-2 gap-sm-4 mb-5 mb-md-0">
                                <a href="https://www.macaron-swap.exchange/#/swap?outputCurrency=0x6eE2a82C585503aCd1509d18f14Aa25e0a841763" target="_blank" rel="noreferrer" className="cs-btn cs-btn2">
                                    Buy Token &nbsp;&nbsp;<i className="fa-solid fa-arrow-right ml-2"></i>
                                </a>
                                <Link to="/stake" className="cs-btn">
                                    Stake Now&nbsp;&nbsp;<i className="fa-solid fa-arrow-right ml-2"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="hero-img">
                                <YouTube videoId="TlGMaEm26_M" />
                                {/* <img src={bannerImg} className="lazy img-fluid" alt="pattern" style={{ "height": "350px", "width": "350px" }} /> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div id="token-section" className="py-4 py-sm-5">
                <div className="container">
                    <div className='token-box'>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div className=''>
                                    <div className="fs-28 mb-4">
                                        MACARON FINANCE VVIP ($FM) Token Contract BSC
                                    </div>
                                    <div className="token-copy d-flex align-items-center overflow-hidden">
                                        <input id="token" type="text" className="form-control border-0 px-4" value="0x6eE2a82C585503aCd1509d18f14Aa25e0a841763" />
                                        <CopyToClipboard text="0x6eE2a82C585503aCd1509d18f14Aa25e0a841763" onCopy={() => {
                                            setAddrcopy(true);
                                            setTimeout(() => {
                                                setAddrcopy(false);
                                            }, 2000)
                                        }}>
                                            <div type="button"
                                                className="align-items-center bg-primary d-flex flex-shrink-0 fs-2 h-100 img justify-content-center text-white">
                                                <img className="img-fluid scale" src={copyImg} alt="" />
                                                {addrcopy && <small className="text-center">copied</small>}
                                            </div>

                                        </CopyToClipboard>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="work-section" className='py-4 py-sm-5'>
                <div className="container">
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-6'>
                            <h1 className='head-title'>How does it work?</h1>

                            <div className="work-wrapper mt-5">
                                <h3><strong>1. Buy token on exchange</strong></h3>
                                <p>USDT coin in wallet. Connect wallet and buy token recieve automatic</p>
                                <h3><strong>2. Keep coins in our wallet</strong></h3>
                                <p>Go to swap platform. First connect & confirm wallet, select day token lock, transfer $FM Coin to the system and comfirm a stake.</p>
                                <h3><strong>3. Enjoy regular profits</strong></h3>
                                <p>You rewards are generated daily until the date lock stake Now it’s time to enjoy your life and finally let your money work for you every day.</p>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-6 d-flex justify-content-end'>
                            <img src={work} className="lazy img-fluid" alt="pattern" style={{ "height": "400px", "width": "400px" }} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="faq-section">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="fs-37">
                                Frequently Asked Questions
                            </div>
                            <div class="faq mb-5">
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                What Fees Do You Charge for Staking?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                Staking,Deposit,Withdraw And Claim Vat 10%.(Automatic LP:5%,Token holder:5%)
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                What is Macaron Finance Stake?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                Macaron Finance Stake is the international community of the global
                                                decentralized ecosystem and the first ever $FM smart contract
                                                marketing network. This is a self-executing software algorithm that
                                                performs the function of distributing partner rewards between
                                                community members, subject to certain conditions (Network marketing
                                                plan). The contract code is publicly available. Information about
                                                transactions can always be viewed at the link bscscan.com
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading21">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                                                what is condition for minimum reward claim?
                                            </button>
                                        </h2>
                                        <div id="collapse21" class="accordion-collapse collapse" aria-labelledby="heading21"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                minimum reward claim is 15 $FM and you can withdraw after a 24 hours of staking.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree">
                                                WHO MANAGES THE PLATFORM?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                The Macaron Finance Stake platform consists of self-executing trades, which do
                                                not permit anyone to interfere with the course of the transactions.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading22">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse22" aria-expanded="false"
                                                aria-controls="collapse22">
                                                WHO CREATED Macaron Stake?
                                            </button>
                                        </h2>
                                        <div id="collapse22" class="accordion-collapse collapse" aria-labelledby="heading22"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                The MACARONSTAKE concept belongs to a group of crypto enthusiasts, who are members of the community and don’t have any special privileges. Today, MACARONSTAKE is an peer-to-peer community of platform members, to whom the platform itself belongs.
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingFive">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFive" aria-expanded="false"
                                                aria-controls="collapseFive">
                                                WHAT IS A SMART CONTRACT? WHAT ARE ITS ADVANTAGES?
                                            </button>
                                        </h2>
                                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                A smart contract is an algorithm within a cryptocurrency’s
                                                blockchain. In our case, $FM is our first choice among those on
                                                which it is possible to create smart contracts. The main purpose of
                                                such contracts is the automation of the relationship, the
                                                opportunity to make a commitment self-executing.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingsix">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapsesix" aria-expanded="false"
                                                aria-controls="collapsesix">
                                                WHAT IS DECENTRALIZATION?
                                            </button>
                                        </h2>
                                        <div id="collapsesix" class="accordion-collapse collapse" aria-labelledby="headingsix"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                The process works based on distributing authority from a centralized
                                                administration to the participants involved in the process. Unlike a
                                                centralized system, all decisions are made by consensus.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingseven">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseseven" aria-expanded="false"
                                                aria-controls="collapseseven">
                                                WHICH WALLET SHOULD I USE?
                                            </button>
                                        </h2>
                                        <div id="collapseseven" class="accordion-collapse collapse" aria-labelledby="headingseven"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                MACARONSTAKE works with all crypto wallets. We recommend the
                                                following: - for mobile devices (smartphone, tablet) the app,<br />
                                                Trust Wallet <a href="https://trustwallet.com/" rel="noreferrer" target="_blank" >https://trustwallet.com/</a>, <br />
                                                Coinbase Wallet (can be used in USA) <a href="https://www.coinbase.com/" rel="noreferrer" target="_blank" >https://www.coinbase.com/</a>, <br />
                                                for computers and laptops browser extension METAMASK <a href=" https://metamask.io/" rel="noreferrer" target="_blank" > https://metamask.io/</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingeight">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseeight" aria-expanded="false"
                                                aria-controls="collapseeight">
                                                WHAT DO I NEED TO DO TO CREATE A TRUST WALLET?
                                            </button>
                                        </h2>
                                        <div id="collapseeight" class="accordion-collapse collapse" aria-labelledby="headingeight"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                To register you will need only a wallet TRUST WALLTE - a Google
                                                Chrome extension (PC) and/or some other applications for mobile
                                                devices. Tested and safe applications: -
                                                wallet TRUST WALLTE <a href="https://trustwallet.com/" rel="noreferrer" target="_blank" >https://trustwallet.com/</a><br />
                                                <a href=" https://metamask.io/" rel="noreferrer" target="_blank" > https://metamask.io/</a> When registering don't forget to securely and
                                                safely store the password for your wallet.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingnine">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapsenine" aria-expanded="false"
                                                aria-controls="collapsenine">
                                                HOW TO BUY/SELL $FM IF I’VE NEVER DEALT WITH CRYPTOCURRENCIES BEFORE?
                                            </button>
                                        </h2> 
                                        <div id="collapsenine" class="accordion-collapse collapse" aria-labelledby="headingnine"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                            There are lots of ways to buy/sell cryptocurrencies in exchange for fiat money (regular currencies). They have all been designed for regular users and have user-friendly interfaces. Your first transaction exchanging fiat money for digital currency will take you no more than five minutes. We recommend using the verified currency exchange aggregator BUY/SELL $FM Vvip link of DEX MacaronSwapExchange.<br /><a href="https://www.macaron-swap.exchange/#/swap?outputCurrency=0x6eE2a82C585503aCd1509d18f14Aa25e0a841763" rel="noreferrer" target="_blank" > https://www.macaron-swap.exchange/#/swap?outputCurrency=0x6eE2a82C585503aCd1509d18f14Aa25e0a841763</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingten">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseten" aria-expanded="false"
                                                aria-controls="collapseten">
                                                HOW CAN I REGISTER ON THE MACARON FINANCE VVIP STAKING PLATFORM?
                                            </button>
                                        </h2>
                                        <div id="collapseten" class="accordion-collapse collapse" aria-labelledby="headingten"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                To register with MACARON FINANCE VVIP STAKING Free Referral Links and Connect Wellte
                                                to the smart contract in order to activate the platforms. The
                                                transaction itself constitutes your registration on the platform.
                                                Please note: when topping up a crypto wallet, you need to account
                                                for the network’s commission, which is usually about 14%.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading11">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse11" aria-expanded="false"
                                                aria-controls="collapse11">
                                                CAN I REGISTER ON THE WEBSITE WITHOUT A PARTNER LINK?
                                            </button>
                                        </h2>
                                        <div id="collapse11" class="accordion-collapse collapse" aria-labelledby="heading11"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                Yes. Registering without an invitation link will put you in team.
                                                WHAT WILL HAPPEN TO MY ACCOUNT IF I TAKE A BREAK FROM WORKING WITH
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading12">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse12" aria-expanded="false"
                                                aria-controls="collapse12">
                                                THE MACARON FINANCE VVIP STAKING COMMUNITY?
                                            </button>
                                        </h2>
                                        <div id="collapse12" class="accordion-collapse collapse" aria-labelledby="heading12"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                No one can close your account, even if they have a strong desire to
                                                do so. The account will always be saved in one of the BSCscan
                                                network blocks. You will continue to receive income from all levels,
                                                except the last active one.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading13">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse13" aria-expanded="false"
                                                aria-controls="collapse13">
                                                I HAVE ACTIVATED THE PLATFORM, WHAT SHOULD I DO NEXT?
                                            </button>
                                        </h2>
                                        <div id="collapse13" class="accordion-collapse collapse" aria-labelledby="heading13"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                In order to effectively interact with the MACARON FINANCE VVIP STAKING platform, you
                                                need to: - Have a chat with whoever invited you or with other
                                                experienced members. They will help you take your first steps.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading14">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse14" aria-expanded="false"
                                                aria-controls="collapse14">
                                                HOW CAN I REACH MY GOALS WITH MACARON FINANCE VVIP STAKING?
                                            </button>
                                        </h2>
                                        <div id="collapse14" class="accordion-collapse collapse" aria-labelledby="heading14"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                            It is based on building a partner network. You tell potential partners about the platform’s potential and encourage them to collaborate with you. Partners who use your link send their first transactions to the smart contract address, and these are then instantly redirected to your wallet. The platform works directly with three marketing plans Refer Friends. Earn Crypto Together Level 1:0.5%, Level 2:0.5%, Level 3:0.5%, Level 4:0.5% ,Level 5: 0.5% <b>**Deposit and claim Vat 10%.**</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading15">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse15" aria-expanded="false"
                                                aria-controls="collapse15">
                                                IS PASSIVE INCOME POSSIBLE?
                                            </button>
                                        </h2>
                                        <div id="collapse15" class="accordion-collapse collapse" aria-labelledby="heading15"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                The MACARON FINANCE VVIP STAKING platform have been built in such a way that all
                                                members have Passive income is possible;
                                                it depends on the activity of partners, who can end up on your
                                                platform via Link your Wellte number. You can get overflows from
                                                higher-ranked or lower-ranked partners, however your income will
                                                depend on their activity. In order to ensure that you have a passive
                                                income in the future, you need to make a determined effort to
                                                attract new partners and open new platforms in. Once you have
                                                invited even a single active person onto your team, you can already
                                                earn good money and achieve your goals. How quickly this happens
                                                depends solely on you.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading16">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse16" aria-expanded="false"
                                                aria-controls="collapse16">
                                                HOW CAN I ATTRACT PEOPLE EFFECTIVELY? WHAT SHOULD I DO IF I CAN’T
                                                ATTRACT ANYONE?
                                            </button>
                                        </h2>
                                        <div id="collapse16" class="accordion-collapse collapse" aria-labelledby="heading16"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                You don’t have to force anyone to take part. Currently, lots of
                                                people are interested in how to earn money on the internet, and some
                                                of them are actively seeking new opportunities. You can meet them on
                                                social networks yourself, or you could set up an automatic sales
                                                funnel, such that anyone who is interested will find you themselves.
                                                Take advantage of your own strengths, watch webinars, ask questions
                                                to experiences platform members and you won’t have to wait long to
                                                succeed. Your results depend solely on you!
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading17">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse17" aria-expanded="false"
                                                aria-controls="collapse17">
                                                HOW MUCH CAN I Earn?
                                            </button>
                                        </h2>
                                        <div id="collapse17" class="accordion-collapse collapse" aria-labelledby="heading17"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                The amount of income depends on the quality indicators of your
                                                activity and the activities of your partners and including you Cycle
                                                the profit to get more Earn all the time.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading18">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse18" aria-expanded="false"
                                                aria-controls="collapse18">
                                                DO I NEED TO WITHDRAW MONEY FROM MACARON FINANCE VVIP STAKING?
                                            </button>
                                        </h2>
                                        <div id="collapse18" class="accordion-collapse collapse" aria-labelledby="heading18"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                When you accumulate 5MF or more and after 24 an hour later, you
                                                can claim immediately so the balance of the smart contract is equal
                                                to zero. Your income arrives instantly into your personal wallet
                                                directly from your partners. Only you have access to your wallet and
                                                no one else can manage your money.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading19">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse19" aria-expanded="false"
                                                aria-controls="collapse19">
                                                CAN I LOSE PARTNER WHOM I HAVE PERSONALLY INVITED?
                                            </button>
                                        </h2>
                                        <div id="collapse19" class="accordion-collapse collapse" aria-labelledby="heading19"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                No. It is not possible.Any partner whom you have invited personally
                                                is permanently bound to you by the referral link wallet address.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading20">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse20" aria-expanded="false"
                                                aria-controls="collapse20">
                                                HOW DOES THIS DIFFER FROM A PYRAMID SCHEME?
                                            </button>
                                        </h2>
                                        <div id="collapse20" class="accordion-collapse collapse" aria-labelledby="heading20"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                Because it does not make any unfulfillable promises to its members. Each person’s success depends on themselves. MACARONSTAKE will remain stable even if it increases in size a thousandfold. MACARONSTAKE does not create debts or other obligations. MACARONSTAKE is a next-gen Staking pay APY platform and has nothing to do with pyramid schemes. The principle of a pyramid scheme is that a large portion of the money is concentrated in the hands of its creators. The earlier you arrive, the more you earn. A pyramid scheme can be closed at any time. Members of the MACARONSTAKE platform, both leaders and newcomers, are on equal terms. No one can terminate the platform’s operation, since its functionality is ensured by a smart contract, which cannot be erased or altered. Even if the website ceases to function, all the data and the entire structure will remain intact, and the smart contract will continue to function, as long as there is electricity and internet access.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="fs-37 text-center">
                                Still have questions?
                            </div>
                            <p class="text-center">
                                If you can not find answer to your question <br />
                                you can mail on macaronswapfinance@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
